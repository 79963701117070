import React from 'react'
import ArticleGrid from '../components/ArticleGrid/ArticleGrid'
import Layout from '../components/Layout'
import Meta from '../components/Meta'
import { siteMetadata } from '../../gatsby-config'

const News = () => {
  return (
    <Layout>
      <Meta site={siteMetadata} title="Aktualności" />
      <div className="container mw-1200">
        <div className="row">
          <div className="col-12 my-5">
            <h1 className="text-center">Aktualności</h1>
          </div>
        </div>
        <ArticleGrid />
      </div>
    </Layout>
  )
}

export default News
