import { StaticQuery, graphql } from 'gatsby'
import React from 'react'
import Card from '../CardBox/CardBox'

const ArticleGrid = ({ articleCol }) => (
  <StaticQuery
    query={graphql`
      query ArticleGridQuery {
        allMarkdownRemark {
          edges {
            node {
              id
              frontmatter {
                title
                path
                description
                category
                image {
                  childImageSharp {
                    fluid(maxWidth: 400) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const articles = data.allMarkdownRemark.edges.map(item => {
        return (
          <div className="col-12 col-sm-6 col-lg-3 d-flex" style={{marginBottom: '1.5em'}} key={item.node.id}>
            <Card data={item.node.frontmatter} />
          </div>
        )
      })
      return <div className="row">{articles}</div>
    }}
  />
)

export default ArticleGrid
